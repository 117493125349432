import React from 'react'
import TopInfo from '../components/mainPage/TopInfo.js'
import Hero from '../components/mainPage/Hero.js'
import AboutUs from '../components/mainPage/AboutUs.js'
import Services from '../components/mainPage/Services.js'
import Footer from '../components/mainPage/Footer.js'

function MainPage() {
  return (
    <div>
        <TopInfo />
        <Hero />
        <Services />
        <AboutUs />
        <Footer />
    </div>
  )
}

export default MainPage