import React from "react";
import { FaEnvelope, FaMapMarked, FaPhone } from "react-icons/fa";

import logo from "../../assets/logo.png";
function TopInfo() {
  return (
    <div className="w-full h-fit bg-[#2A9EDA] flex">
      <div className="md:w-[90%] mx-auto flex text-slate-100 flex-shrink md:text-base text-xs justify-between p-2">
        <div className="lg:flex hidden">
          <div className="phoneData   flex   p-2 min-w-fit">
            <p className="p-3">
              <FaPhone />
            </p>
            <p className="p-2 ">
              <a href="tel:604086394">604 086 394</a>
            </p>
          </div>

          <div className="emailData   flex  xl:ml-5  p-2">
            <p className="p-3">
              <FaMapMarked />
            </p>
            <p className="p-2">
              <a href="">Widzewska 14, Łódź</a>
            </p>
          </div>

          <div className="emailData   flex  xl:ml-5  p-2">
            <p className="p-3">
              <FaEnvelope />
            </p>
            <p className="p-2">
              <a href="mailto:biuro@md.com.pl">biuro@md.com.pl</a>
            </p>
          </div>
        </div>
        <div className="lg:w-[13%] w-[25%] my-auto">
          <img src={logo} alt="" />
        </div>
      </div>
    </div>
  );
}

export default TopInfo;
