import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import MainPage from "./pages/MainPage";
import UnderConstructionPage from "./pages/UnderConstructionPage";

function App() {
  return (
    <div className="m-0 p-0">
      <BrowserRouter>
        <Routes>
          <Route index element={<MainPage />}></Route>
          <Route path='/' element={<MainPage />}></Route>
          <Route path='/w-budowie' element={<UnderConstructionPage/>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
