import React from "react";
import { Link as ScrollLink } from "react-scroll";
import {Link as OuterLink} from "react-router-dom"

import "./Services.css";

function Services() {
  return (
    <div className="w-full flex content pb-10" name="offer">
      <div className="w-[100%] lg:w-[90%] md:w-[80%] mx-auto flex flex-col">
        {/* UP PART */}
        {/* DOWN PART */}
        <div className="2xl:w-[70%] w-full flex bg-transparent xl:flex-row flex-col justify-between mx-auto py-10">
          <div class="product-card">
            <div class="product-tumb">
              <img src="https://i.imgur.com/xdbHo4E.png" alt="" />
            </div>
            <div class="product-details">
              <h4>
                <OuterLink to="/w-budowie">Armatura i urządzenia wodno-kanalizacyjne</OuterLink>
              </h4>
              <div class="product-bottom-details">
                <OuterLink to="/w-budowie">
                  <button className="flex bg-[#2A9EDA] text-white min-w-[200px] rounded-full tracking-wider xl:p-3 p-1 ease-in duration-500 hover:bg-transparent hover:border-[1px] hover:border-[#2A9EDA] hover:text-[#2A9EDA] mx-auto">
                    <span className="p-2 mx-auto">Sprawdź ofertę!</span>
                  </button>
                </OuterLink>
              </div>
            </div>
          </div>
          <div class="product-card">
            <div class="badge">NOWOŚĆ</div>
            <div class="product-tumb2">

            </div>
            <div class="product-details">
              <h4>
                <a href="https://mittelmann.com/">
                  Techniki bezpieczeństwa Systemy ewakuacji Asekuracja przy
                  pracy
                </a>
              </h4>
              <div class="product-bottom-details">
                <a
                href="https://mittelmann.pl/" target="_blank"
                >
                  <button className="flex bg-[#2A9EDA] text-white min-w-[200px] rounded-full tracking-wider xl:p-3 p-1 ease-in duration-500 hover:bg-transparent hover:border-[1px] hover:border-[#2A9EDA] hover:text-[#2A9EDA] mx-auto">
                    <span className="p-2 mx-auto">Sprawdź ofertę!</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div class="product-card">
            <div class="product-tumb3">
              
            </div>
            <div class="product-details">
              <h4>
                <a href="https://rebet.pl/">Specjalistyczna zaprawa mineralna REBET</a>
              </h4>
              <div class="product-bottom-details">
                <a
                href="https://rebet.pl/" target="_blank"
                >
                  <button className="flex mx-auto bg-[#2A9EDA] text-white min-w-[200px] rounded-full tracking-wider xl:p-3 p-1 ease-in duration-500 hover:bg-transparent hover:border-[1px] hover:border-[#2A9EDA] hover:text-[#2A9EDA]">
                    <span className="p-2 mx-auto">Sprawdź ofertę!</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
