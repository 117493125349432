import React from "react";
import { Link as ScrollLink } from "react-scroll";
import "./AboutUs.css";

import image1 from "../../assets/workers1.jpeg";
import image3 from "../../assets/workirebet.jpg";

function AboutUs() {
  return (
    <div
      className="containerForAll     w-full 2xl:h-[80vh] h-fit flex flex-col lg:flex-row text-center xl:text-left"
      name="about"
    >
      <div className="roundedImages          w-[90%] lg:w-[40%] flex mx-auto my-auto">
      <img className="mx-auto" src={image3} alt="sq-sample14" />
      </div>
      <div className="overlayingDivWithText       lg:w-1/2 h-full bg-slate-200 flex p-5">
        <div className="flex flex-col w-[95%] lg:w-[80%] mx-auto my-auto">
          <h1 className="xl:text-5xl text-2xl p-5">Kim jesteśmy?</h1>
          <div className="bg-[#2A9EDA] h-[1px] w-[50%] xl:ml-5 mx-auto"></div>
          <p className="font-light text-md p-5">
          Przedsiębiorstwo MD działa na polskim rynku od 1992 roku. Początkowo funkcjonowało pod nazwą Przedsiębiorstwo MD Mieczysław Dobrynin, a od 2011 roku jako Przedsiębiorstwo MD Sp. z o.o. W początkowym okresie firma głównie zajmowała się produkcją i dystrybucją urządzeń dla infrastruktury wodno-kanalizacyjnej, takich jak urządzenia naprawcze, rury, galanteria drogowa oraz armatura. W tym czasie firma współpracowała zarówno z producentami polskimi (Jafar SA, Materbud), jak i zagranicznymi (UPE Engineering, Saint-Gobain, Mittelmann, EWE Armaturren).
          </p>
          <p className="font-light text-md p-5">
          Obecnie firma skoncentrowana jest na produkcji specjalistycznej zaprawy mineralnej REBET, służącej do hydroizolacji i renowacji obiektów budowlanych, oraz dystrybucją urządzeń ratowniczo-ewakuacyjnych firmy Mittelmann.
          </p>
          <div className="flex xl:w-fit w-fit mx-auto p-5 border">
            <ScrollLink to="offer" smooth={true} duration={500}>
              <button className="flex bg-[#2A9EDA] mx-auto text-white min-w-[200px] rounded-full tracking-wider xl:p-3 p-1 ease-in duration-500 hover:bg-transparent hover:border-[1px] hover:border-[#2A9EDA] hover:text-[#2A9EDA] xl:mr-3">
                <p className="p-2 mx-auto">Sprawdź ofertę!</p>
              </button>
            </ScrollLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
