import React from "react";
import { FaEnvelope, FaMapMarked, FaPhone } from "react-icons/fa";

function Footer() {
  return (
    <div className="w-full h-fit bg-[#040d12] flex font-light">
      <div className="md:w-[90%] mx-auto flex flex-col text-slate-100 md:text-base text-xs justify-between p-2">
        <div className="flex mx-auto flex-col md:flex-row">
          <div className="phoneData   flex   p-2 min-w-fit">
            <p className="p-3">
              <FaPhone />
            </p>
            <p className="p-2 ">
              <a href="tel:604086394">604 086 394</a>
            </p>
          </div>

          <div className="emailData   flex  xl:ml-5  p-2">
            <p className="p-3">
              <FaMapMarked />
            </p>
            <p className="p-2">
              <a href="">Widzewska 14, Łódź</a>
            </p>
          </div>

          <div className="emailData   flex  xl:ml-5  p-2">
            <p className="p-3">
              <FaEnvelope />
            </p>
            <p className="p-2">
              <a href="mailto:biuro@md.com.pl">biuro@md.com.pl</a>
            </p>
          </div>
        </div>
        {/* <div className="w-full flex">
          <p className="w-fit h-fit mx-auto text-sm">
            All Rights Reserved® JSTAgency
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default Footer;
