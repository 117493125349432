import React from "react";

const UnderConstructionPage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="max-w-md p-8 bg-white shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">
          Strona w budowie
        </h1>
        <p className="text-lg text-center text-gray-600 mb-6">
          W dalszym ciągu pracujemy nad tą częścią witryny, która niebawem
          będzie gotowa do użytku. Dziękujemy za Państwa cieprliwość i do
          zobaczenia!
        </p>
        <p className="text-sm text-center text-gray-500">
          Jeśli mają Państwo pytania, zapraszamy do kontaktu{" "}
          <a href="mailto:biuro@md.com.pl" className="text-blue-500">
            biuro@md.com.pl
          </a>
        </p>
      </div>
    </div>
  );
};

export default UnderConstructionPage;
