import React from "react";
import "./Hero.css";
import { Link as ScrollLink } from "react-scroll";

function Hero() {
  return (
    <div className="containerForAll     backgroundImage w-full lg:h-[90vh] h-[60vh]">
      <div className="overlayingDivWithText       lg:w-1/2 w-full h-full bg-slate-200 flex text-center lg:text-left">
        <div className="flex flex-col 2xl:w-[70%] w-[95%] mx-auto my-auto">
          <h1 className="xl:text-6xl text-2xl p-5">
            Innowacje i solidność. Twoje cele nasze rozwiązania.
          </h1>
          <div className="bg-[#2A9EDA] h-[1px] w-[50%] lg:ml-5 mx-auto"></div>
          <p className="xl:text-lg font-light text-md p-5 tracking-wide">
          Nasza firma to nie tylko doskonała jakość produktów, ale również kompetentny zespół ekspertów, gotowy służyć Ci wsparciem i fachową wiedzą. Pomagamy w doborze najlepszych rozwiązań hydroizolacyjnych, udzielamy porad technicznych i zapewnimy Ci pełne wsparcie na każdym etapie realizacji projektu.
          </p>
          <div className="flex w-full lg:w-[60%] justify-evenly p-5 md:ml-5 m-0">
            <ScrollLink to="offer" smooth={true} duration={500}>
              <button className="flex bg-[#2A9EDA] text-white xl:min-w-[200px] min-w-[150px] rounded-full tracking-wider xl:p-3 p-1 ease-in duration-500 hover:bg-transparent hover:border-[1px] hover:border-[#2A9EDA] hover:text-[#2A9EDA] mr-3">
                <p className="p-2 mx-auto">Sprawdź ofertę!</p>
              </button>
            </ScrollLink>
            <ScrollLink to="about" smooth={true} duration={500}>
              <button className="flex border-[1px] border-[#2A9EDA]  bg-transpaent xl:min-w-[200px] min-w-[150px] rounded-full text-[#2A9EDA] tracking-wider xl:p-3 p-1 ease-in duration-500 hover:text-slate-100 hover:bg-[#2A9EDA] ml-3">
                <p className="p-2 mx-auto">Czytaj dalej!</p>
              </button>
            </ScrollLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
